define("discourse/plugins/discourse-prometheus-alert-receiver/discourse/components/alert-receiver/date-range", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <span>
    <AlertReceiver::Timestamp
      @date={{this.parsedStart.date}}
      @time={{this.parsedStart.time}}
    />
  
    {{#if @endsAt}}
      -&nbsp;<AlertReceiver::Timestamp
        @date={{this.parsedEnd.date}}
        @time={{this.parsedEnd.time}}
        @hideDate={{this.hideEndDate}}
      />
    {{/if}}
  </span>
  */
  {
    "id": "WKhwMi5A",
    "block": "[[[10,1],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@date\",\"@time\"],[[30,0,[\"parsedStart\",\"date\"]],[30,0,[\"parsedStart\",\"time\"]]]],null],[1,\"\\n\\n\"],[41,[30,1],[[[1,\"    - \"],[8,[39,1],null,[[\"@date\",\"@time\",\"@hideDate\"],[[30,0,[\"parsedEnd\",\"date\"]],[30,0,[\"parsedEnd\",\"time\"]],[30,0,[\"hideEndDate\"]]]],null],[1,\"\\n\"]],[]],null],[13]],[\"@endsAt\"],false,[\"span\",\"alert-receiver/timestamp\",\"if\"]]",
    "moduleName": "discourse/plugins/discourse-prometheus-alert-receiver/discourse/components/alert-receiver/date-range.hbs",
    "isStrictMode": false
  });
  function parseTimestamp(timestamp) {
    if (timestamp) {
      const [date, time] = timestamp.split("T");
      return {
        date,
        time
      };
    }
  }
  class AlertReceiverDateRange extends _component2.default {
    get parsedStart() {
      return parseTimestamp(this.args.startsAt);
    }
    get parsedEnd() {
      return parseTimestamp(this.args.endsAt);
    }
    get hideEndDate() {
      return this.parsedStart.date === this.parsedEnd?.date;
    }
  }
  _exports.default = AlertReceiverDateRange;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AlertReceiverDateRange);
});