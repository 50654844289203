define("discourse/plugins/discourse-prometheus-alert-receiver/discourse/components/alert-receiver/table", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="alert-receiver-table" data-alert-status={{@statusName}}>
    <AlertReceiver::CollapseToggle
      @heading={{@heading}}
      @count={{@alerts.length}}
      @headingLink={{@headingLink}}
      @collapsed={{this.isCollapsed}}
      @toggleCollapsed={{this.toggleCollapsed}}
    />
  
    {{#unless this.isCollapsed}}
      <div class="alert-table-wrapper">
        <table class="prom-alerts-table">
          <tbody>
            {{#each @alerts as |alert|}}
              <AlertReceiver::Row
                @alert={{alert}}
                @showDescription={{this.showDescriptionColumn}}
              />
            {{/each}}
          </tbody>
        </table>
      </div>
    {{/unless}}
  </div>
  */
  {
    "id": "Pw0GAKU9",
    "block": "[[[10,0],[14,0,\"alert-receiver-table\"],[15,\"data-alert-status\",[30,1]],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@heading\",\"@count\",\"@headingLink\",\"@collapsed\",\"@toggleCollapsed\"],[[30,2],[30,3,[\"length\"]],[30,4],[30,0,[\"isCollapsed\"]],[30,0,[\"toggleCollapsed\"]]]],null],[1,\"\\n\\n\"],[41,[51,[30,0,[\"isCollapsed\"]]],[[[1,\"    \"],[10,0],[14,0,\"alert-table-wrapper\"],[12],[1,\"\\n      \"],[10,\"table\"],[14,0,\"prom-alerts-table\"],[12],[1,\"\\n        \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[30,3]],null]],null],null,[[[1,\"            \"],[8,[39,7],null,[[\"@alert\",\"@showDescription\"],[[30,5],[30,0,[\"showDescriptionColumn\"]]]],null],[1,\"\\n\"]],[5]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"@statusName\",\"@heading\",\"@alerts\",\"@headingLink\",\"alert\"],false,[\"div\",\"alert-receiver/collapse-toggle\",\"unless\",\"table\",\"tbody\",\"each\",\"-track-array\",\"alert-receiver/row\"]]",
    "moduleName": "discourse/plugins/discourse-prometheus-alert-receiver/discourse/components/alert-receiver/table.hbs",
    "isStrictMode": false
  });
  class AlertReceiverTable extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "collapsed", [_tracking.tracked]))();
    #collapsed = (() => (dt7948.i(this, "collapsed"), void 0))();
    get isCollapsed() {
      return this.collapsed ?? this.args.defaultCollapsed;
    }
    get showDescriptionColumn() {
      return this.args.alerts.any(a => a.description);
    }
    toggleCollapsed() {
      this.collapsed = !this.isCollapsed;
    }
    static #_2 = (() => dt7948.n(this.prototype, "toggleCollapsed", [_object.action]))();
  }
  _exports.default = AlertReceiverTable;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AlertReceiverTable);
});