define("discourse/plugins/discourse-prometheus-alert-receiver/discourse/components/alert-receiver/data", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each this.groupedByStatus as |statusGroup|}}
    <h2>
      {{#if statusGroup.emoji}}{{emoji statusGroup.emoji}}{{/if}}
      {{i18n statusGroup.titleKey}}
    </h2>
    {{#each statusGroup.groupedByDc as |groupedByDc|}}
      <AlertReceiver::Table
        @statusName={{statusGroup.statusName}}
        @alerts={{groupedByDc.alerts}}
        @heading={{groupedByDc.dcName}}
        @headingLink={{groupedByDc.headingLink}}
        @defaultCollapsed={{statusGroup.defaultCollapsed}}
      />
    {{/each}}
  {{/each}}
  */
  {
    "id": "Dp+EPcGU",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"groupedByStatus\"]]],null]],null],null,[[[1,\"  \"],[10,\"h2\"],[12],[1,\"\\n    \"],[41,[30,1,[\"emoji\"]],[[[1,[28,[35,4],[[30,1,[\"emoji\"]]],null]]],[]],null],[1,\"\\n    \"],[1,[28,[35,5],[[30,1,[\"titleKey\"]]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1,[\"groupedByDc\"]]],null]],null],null,[[[1,\"    \"],[8,[39,6],null,[[\"@statusName\",\"@alerts\",\"@heading\",\"@headingLink\",\"@defaultCollapsed\"],[[30,1,[\"statusName\"]],[30,2,[\"alerts\"]],[30,2,[\"dcName\"]],[30,2,[\"headingLink\"]],[30,1,[\"defaultCollapsed\"]]]],null],[1,\"\\n\"]],[2]],null]],[1]],null]],[\"statusGroup\",\"groupedByDc\"],false,[\"each\",\"-track-array\",\"h2\",\"if\",\"emoji\",\"i18n\",\"alert-receiver/table\"]]",
    "moduleName": "discourse/plugins/discourse-prometheus-alert-receiver/discourse/components/alert-receiver/data.hbs",
    "isStrictMode": false
  });
  const STATUS_NAMES = ["firing", "suppressed", "stale", "resolved"];
  const STATUS_EMOJIS = {
    firing: "fire",
    suppressed: "shushing_face"
  };
  const COLLAPSE_THRESHOLD = 30;
  class AlertReceiverData extends _component2.default {
    get groupedByStatus() {
      const alerts = this.args.topic.get("alert_data");
      const byStatus = {};
      const statusCounts = {};
      alerts.forEach(a => {
        const byDc = byStatus[a.status] ??= {};
        statusCounts[a.status] ??= 0;
        const listForStatusAndDc = byDc[a.datacenter] ??= [];
        listForStatusAndDc.push(a);
        statusCounts[a.status] += 1;
      });
      let defaultCollapsed = false;
      return STATUS_NAMES.map(statusName => {
        const byDc = byStatus[statusName];
        if (!byDc) {
          return;
        }
        if (statusCounts[statusName] > COLLAPSE_THRESHOLD) {
          defaultCollapsed = true;
        }
        const orderedDcData = Object.entries(byDc).map(_ref => {
          let [dcName, dcAlerts] = _ref;
          return {
            alerts: dcAlerts,
            dcName,
            headingLink: dcAlerts[0].external_url
          };
        }).sort((a, b) => a.dcName > b.dcName ? 1 : -1);
        return {
          statusName,
          emoji: STATUS_EMOJIS[statusName],
          titleKey: `prom_alert_receiver.headers.${statusName}`,
          defaultCollapsed,
          groupedByDc: orderedDcData
        };
      }).filter(Boolean);
    }
    static #_ = (() => dt7948.n(this.prototype, "groupedByStatus", [_tracking.cached]))();
  }
  _exports.default = AlertReceiverData;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AlertReceiverData);
});