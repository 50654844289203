define("discourse/plugins/discourse-prometheus-alert-receiver/discourse/components/alert-receiver/row", ["exports", "@ember/component", "@glimmer/component", "@ember/controller", "@ember/object", "@ember/service", "I18n", "@ember/template-factory"], function (_exports, _component, _component2, _controller, _object, _service, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <tr>
    <td><a href={{this.generatorUrl}}>{{@alert.identifier}}</a></td>
    <td>
      <AlertReceiver::DateRange
        @startsAt={{@alert.starts_at}}
        @endsAt={{@alert.ends_at}}
      />
    </td>
    {{#if @showDescription}}
      <td>{{@alert.description}}</td>
    {{/if}}
    <td>
      <div>
        {{#if this.linkUrl}}
          <a
            class="btn btn-flat no-text btn-icon"
            href={{this.linkUrl}}
            title={{this.linkText}}
          >
            {{d-icon "up-right-from-square"}}
          </a>
        {{/if}}
        <DButton
          @action={{this.quoteAlert}}
          @icon="quote-left"
          @title="prom_alert_receiver.actions.quote"
          class="btn-flat"
        />
      </div>
    </td>
  </tr>
  */
  {
    "id": "vEF/lVlL",
    "block": "[[[10,\"tr\"],[12],[1,\"\\n  \"],[10,\"td\"],[12],[10,3],[15,6,[30,0,[\"generatorUrl\"]]],[12],[1,[30,1,[\"identifier\"]]],[13],[13],[1,\"\\n  \"],[10,\"td\"],[12],[1,\"\\n    \"],[8,[39,3],null,[[\"@startsAt\",\"@endsAt\"],[[30,1,[\"starts_at\"]],[30,1,[\"ends_at\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[30,2],[[[1,\"    \"],[10,\"td\"],[12],[1,[30,1,[\"description\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,\"td\"],[12],[1,\"\\n    \"],[10,0],[12],[1,\"\\n\"],[41,[30,0,[\"linkUrl\"]],[[[1,\"        \"],[10,3],[14,0,\"btn btn-flat no-text btn-icon\"],[15,6,[30,0,[\"linkUrl\"]]],[15,\"title\",[30,0,[\"linkText\"]]],[12],[1,\"\\n          \"],[1,[28,[35,6],[\"up-right-from-square\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[8,[39,7],[[24,0,\"btn-flat\"]],[[\"@action\",\"@icon\",\"@title\"],[[30,0,[\"quoteAlert\"]],\"quote-left\",\"prom_alert_receiver.actions.quote\"]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@alert\",\"@showDescription\"],false,[\"tr\",\"td\",\"a\",\"alert-receiver/date-range\",\"if\",\"div\",\"d-icon\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-prometheus-alert-receiver/discourse/components/alert-receiver/row.hbs",
    "isStrictMode": false
  });
  class AlertReceiverRow extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "topicController", [(0, _controller.inject)("topic")]))();
    #topicController = (() => (dt7948.i(this, "topicController"), void 0))();
    get generatorUrl() {
      const {
        generator_url: url,
        starts_at: startsAt,
        ends_at: endsAt
      } = this.args.alert;
      return this.processUrl(url, startsAt, endsAt);
    }
    get linkUrl() {
      const {
        link_url: url,
        starts_at: startsAt,
        ends_at: endsAt
      } = this.args.alert;
      return this.processUrl(url, startsAt, endsAt);
    }
    get linkText() {
      return this.args.alert.link_text || _I18n.default.t("prom_alert_receiver.actions.link");
    }
    processUrl(urlString, startsAt, endsAt) {
      endsAt ||= new Date().toISOString();
      try {
        const url = new URL(urlString);
        return this.buildPrometheusUrl(url, startsAt, endsAt) || this.buildGrafanaUrl(url, startsAt, endsAt) || this.buildKibanaUrl(url, startsAt, endsAt) || url;
      } catch (e) {
        if (e instanceof TypeError) {
          // Invalid or blank URL
          return;
        }
        throw e;
      }
    }
    checkMatch(url, regexString) {
      if (!regexString || !url) {
        return false;
      }
      try {
        const regexp = new RegExp(regexString);
        return regexp.test(url);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error("Alert receiver regex error", e);
      }
      return false;
    }
    buildGrafanaUrl(url, startsAt, endsAt) {
      const regex = this.siteSettings.prometheus_alert_receiver_grafana_regex;
      if (!this.checkMatch(url, regex)) {
        return;
      }
      const start = new Date(startsAt);
      const end = new Date(endsAt);

      // Grafana uses milliseconds since epoch
      url.searchParams.set("from", start.getTime());
      url.searchParams.set("to", end.getTime());
      return url.toString();
    }
    buildKibanaUrl(url, startsAt, endsAt) {
      const regex = this.siteSettings.prometheus_alert_receiver_kibana_regex;
      if (!this.checkMatch(url, regex)) {
        return;
      }

      // Kibana stores its data after the # in the URL
      // So some custom parsing is required
      const fragment = url.hash;
      const parts = fragment.split("?", 2);
      const searchParams = new URLSearchParams(parts[1]);
      searchParams.set("_g", `(time:(from:'${startsAt}',mode:absolute,to:'${endsAt}'))`);
      url.hash = `${parts[0]}?${searchParams}`;
      return url.toString();
    }
    buildPrometheusUrl(url, startsAt, endsAt) {
      const regex = this.siteSettings.prometheus_alert_receiver_prometheus_regex;
      if (!this.checkMatch(url, regex)) {
        return;
      }
      const start = new Date(startsAt);
      const end = new Date(endsAt);

      // Make the graph window 5 minutes either side of the alert
      const windowDuration = (end - start) / 1000 + 600; // Add 10 minutes
      const windowEndDate = new Date(end.getTime() + 300 * 1000); // Shift 5 minutes forward

      url.searchParams.set("g0.range_input", `${Math.ceil(windowDuration)}s`);
      url.searchParams.set("g0.end_input", windowEndDate.toISOString());
      url.searchParams.set("g0.tab", "0");
      return url.toString();
    }
    quoteAlert() {
      const {
        identifier,
        datacenter,
        description,
        starts_at: startsAt
      } = this.args.alert;
      const [date, time] = startsAt.split("T");
      let alertString = `**${identifier}** - ${datacenter}`;
      alertString += ` - [date=${date} time=${time} displayedTimezone=UTC format="YYYY-MM-DD HH:mm"]`;
      if (description) {
        alertString += ` - ${description}`;
      }
      this.topicController.quoteState.selected(this.topicController.model.postStream.stream[0], alertString, {});
      this.topicController.send("selectText");
    }
    static #_3 = (() => dt7948.n(this.prototype, "quoteAlert", [_object.action]))();
  }
  _exports.default = AlertReceiverRow;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AlertReceiverRow);
});